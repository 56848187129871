import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompanyShowItem,
  CreateNewCompanyRequestPayload,
  DeleteCrmCompanyPayload,
  GetCompanyDetailsPayload,
  ManageCompanyState,
  UpdateCrmCompanyDetailsPayload,
} from '@redux/types/crm/companies/companiesTypes';
import { IComment } from '@shared/models/comment';
import { Company } from '@shared/models/company/company';

const storeName = 'companiesManage';

const initialState: ManageCompanyState = {
  createLoading: false,
  deletingCompanyLoading: false,
  detailsLoading: false,
  updateDetailsLoading: false,
};

const companiesManageSlice = createSlice({
  name: storeName,
  initialState,

  reducers: {
    setCreateLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
      state.error = undefined;
    },
    createCompanySuccess(state: ManageCompanyState, action: PayloadAction<Company>) {
      state.companyData = action.payload;
      state.createLoading = false;
    },
    createCompanyFailure(state: ManageCompanyState, action: PayloadAction<string>) {
      state.error = action.payload;
      state.createLoading = false;
    },
    setDetailsLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.detailsLoading = action.payload;
      state.details = undefined;
      state.detailsError = undefined;
    },
    getDetailsSuccess(state: ManageCompanyState, action: PayloadAction<CompanyShowItem>) {
      state.details = action.payload;
      state.detailsLoading = false;
    },
    getDetailsFailure(state: ManageCompanyState, action: PayloadAction<string>) {
      state.detailsError = action.payload;
      state.detailsLoading = false;
    },
    setComments(state: ManageCompanyState, action: PayloadAction<IComment[]>) {
      if (state?.details) {
        state.details = {
          ...state.details,
          comments: action.payload,
        };
      }
    },
    updateComment(state: ManageCompanyState, action: PayloadAction<IComment>) {
      if (state?.details && state?.details?.comments) {
        state.details.comments = state.details.comments.map((comment) => {
          if (comment.id === action.payload.id) {
            return action.payload;
          }

          return comment;
        });
      }
    },
    deleteComment(state: ManageCompanyState, action: PayloadAction<Id>) {
      if (state?.details) {
        state.details.comments = state.details.comments.filter((comment) => comment.id !== action.payload);
      }
    },
    setDeleteCompanyLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.deletingCompanyLoading = action.payload;
    },
    deleteCompanySuccess(state: ManageCompanyState) {
      state.deletingCompanyLoading = false;
    },
    deleteCompanyFailure(state: ManageCompanyState) {
      state.deletingCompanyLoading = false;
    },
    setUpdateDetailsLoading(state: ManageCompanyState, action: PayloadAction<boolean>) {
      state.updateDetailsLoading = action.payload;
    },
    updateDetailsSuccess(state: ManageCompanyState, action: PayloadAction<CompanyShowItem>) {
      state.details = action.payload;
      state.updateDetailsLoading = false;
    },
    updateDetailsFailure(state: ManageCompanyState) {
      state.updateDetailsLoading = false;
    },
  },
});

export const companyManageActions = {
  setCreateLoading: companiesManageSlice.actions.setCreateLoading,
  createCompanySuccess: companiesManageSlice.actions.createCompanySuccess,
  createCompanyFailure: companiesManageSlice.actions.createCompanyFailure,
  createCompanyRequest: createAction<CreateNewCompanyRequestPayload>(`${storeName}/createCompanyRequest`),

  setDetailsLoading: companiesManageSlice.actions.setDetailsLoading,
  getDetailsSuccess: companiesManageSlice.actions.getDetailsSuccess,
  getDetailsFailure: companiesManageSlice.actions.getDetailsFailure,
  getDetailsRequest: createAction<GetCompanyDetailsPayload['payload']>(`${storeName}/getDetailsRequest`),

  setComments: companiesManageSlice.actions.setComments,
  updateComment: companiesManageSlice.actions.updateComment,
  deleteComment: companiesManageSlice.actions.deleteComment,

  setDeleteCompanyLoading: companiesManageSlice.actions.setDeleteCompanyLoading,
  deleteCompanySuccess: companiesManageSlice.actions.deleteCompanySuccess,
  deleteCompanyFailure: companiesManageSlice.actions.deleteCompanyFailure,
  deleteCompanyRequest: createAction<DeleteCrmCompanyPayload['payload']>(`${storeName}/deleteContactRequest`),

  setUpdateDetailsLoading: companiesManageSlice.actions.setUpdateDetailsLoading,
  updateDetailsSuccess: companiesManageSlice.actions.updateDetailsSuccess,
  updateDetailsFailure: companiesManageSlice.actions.updateDetailsFailure,
  updateDetailsRequest: createAction<UpdateCrmCompanyDetailsPayload>(`${storeName}/updateDetailsRequest`),
};

export const companiesManageReducer = companiesManageSlice.reducer;
