import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ContactShowItem,
  CreateContactRequestPayload,
  DeleteCrmContactPayload,
  GetCrmContactDetailsPayload,
  ManageContactState,
  UpdateCrmContactDetailsPayload,
} from '@redux/types/crm/contacts/contactsTypes';
import { IComment } from '@shared/models/comment';

const storeName = 'manageContact';

const initialState: ManageContactState = {
  createLoading: false,
  detailsLoading: false,
  updateDetailsLoading: false,
  deletingContactLoading: false,
};

const contactManageSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setCreateLoading(state: ManageContactState, action: PayloadAction<boolean>) {
      state.createLoading = action.payload;
    },
    createContactSuccess(state: ManageContactState) {
      state.createLoading = false;
    },
    createContactFailure(state: ManageContactState, action: PayloadAction<ItemString>) {
      state.createError = action.payload;
      state.createLoading = false;
    },
    setDetailsLoading(state: ManageContactState, action: PayloadAction<boolean>) {
      state.detailsLoading = action.payload;
      state.details = undefined;
      state.detailsError = undefined;
    },
    getDetailsSuccess(state: ManageContactState, action: PayloadAction<ContactShowItem>) {
      state.details = action.payload;
      state.detailsLoading = false;
    },
    getDetailsFailure(state: ManageContactState, action: PayloadAction<string>) {
      state.detailsLoading = false;
      state.detailsError = action.payload;
    },
    setComments(state: ManageContactState, action: PayloadAction<IComment[]>) {
      if (state?.details) {
        state.details = {
          ...state.details,
          comments: action.payload,
        };
      }
    },
    updateComment(state: ManageContactState, action: PayloadAction<IComment>) {
      if (state?.details && state?.details?.comments) {
        state.details.comments = state.details.comments.map((comment) => {
          if (comment.id === action.payload.id) {
            return action.payload;
          }

          return comment;
        });
      }
    },
    deleteComment(state: ManageContactState, action: PayloadAction<number>) {
      if (state?.details) {
        state.details.comments = state.details.comments.filter((comment) => comment.id !== action.payload);
      }
    },
    setUpdateDetailsLoading(state: ManageContactState, action: PayloadAction<boolean>) {
      state.updateDetailsLoading = action.payload;
    },
    updateDetailsSuccess(state: ManageContactState, action: PayloadAction<ContactShowItem>) {
      state.details = action.payload;
      state.updateDetailsLoading = false;
    },
    updateDetailsFailure(state: ManageContactState) {
      state.updateDetailsLoading = false;
    },
    setDeleteContactLoading(state: ManageContactState, action: PayloadAction<boolean>) {
      state.deletingContactLoading = action.payload;
    },
    deleteContactSuccess(state: ManageContactState) {
      state.deletingContactLoading = false;
    },
    deleteContactFailure(state: ManageContactState) {
      state.deletingContactLoading = false;
    },
  },
});

export const contactManageActions = {
  setCreateLoading: contactManageSlice.actions.setCreateLoading,
  createContactSuccess: contactManageSlice.actions.createContactSuccess,
  createContactFailure: contactManageSlice.actions.createContactFailure,
  createContactRequest: createAction<CreateContactRequestPayload>(`${storeName}/createContactRequest`),

  setDetailsLoading: contactManageSlice.actions.setDetailsLoading,
  getDetailsSuccess: contactManageSlice.actions.getDetailsSuccess,
  getDetailsFailure: contactManageSlice.actions.getDetailsFailure,
  getDetailsRequest: createAction<GetCrmContactDetailsPayload['payload']>(`${storeName}/getDetailsRequest`),

  setComments: contactManageSlice.actions.setComments,
  updateComment: contactManageSlice.actions.updateComment,
  deleteComment: contactManageSlice.actions.deleteComment,

  setUpdateDetailsLoading: contactManageSlice.actions.setUpdateDetailsLoading,
  updateDetailsSuccess: contactManageSlice.actions.updateDetailsSuccess,
  updateDetailsFailure: contactManageSlice.actions.updateDetailsFailure,
  updateDetailsRequest: createAction<UpdateCrmContactDetailsPayload>(`${storeName}/updateDetailsRequest`),

  setDeleteContactLoading: contactManageSlice.actions.setDeleteContactLoading,
  deleteContactSuccess: contactManageSlice.actions.deleteContactSuccess,
  deleteContactFailure: contactManageSlice.actions.deleteContactFailure,
  deleteContactRequest: createAction<DeleteCrmContactPayload['payload']>(`${storeName}/deleteContactRequest`),
};

export const contactManageReducer = contactManageSlice.reducer;
